import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert/Alert';
import { useTranslation } from 'react-i18next';
import LoadingBar from './loading_bar';
import { isContentReviewer, isManager } from '../lib/user_helpers';

const delayKey = 'pageDelayLoading';
export function setNextPageDelay(timeMillis: number) {
  localStorage.setItem(delayKey, timeMillis.toString());
}
function getPageDelay(): number|null {
  const res = localStorage.getItem(delayKey);
  localStorage.removeItem(delayKey);
  return res ? parseInt(res, 10) : null;
}

interface IParams { page: string }
export const AsyncPage = (props:IParams) => {
  const Component = React.lazy(() => {
    const loadPage = () => import(`../pages/${props.page}`);
    const pageDelay = getPageDelay();
    return !pageDelay ? loadPage() : new Promise((resolve) => {
      setTimeout(() => resolve(loadPage()), pageDelay);
    });
  });
  const fallback = <LoadingBar />;
  return <React.Suspense fallback={fallback}><Component /></React.Suspense>;
};

function AppRoutes() {
  const [t] = useTranslation(['common']);
  return (
    <Routes>
      <Route path="/" element={<AsyncPage page="articles/index_page/index" />} />
      <Route path="articles" element={<AsyncPage page="articles/index_page/index" />} />
      <Route path="articles/search/:articleNo" element={<AsyncPage page="articles/search_page/index" />} />
      <Route path="articles/:articleNo/preview" element={<AsyncPage page="articles/preview_page/index" />} />
      <Route path="articles/:articleNo" element={<AsyncPage page="articles/form_page/index" />} />
      <Route path="admin">
        { isContentReviewer()
          && <Route path="/dashboard" element={<AsyncPage page="admin/dashboard_page/index" />} />}
        { isManager()
          && (
          <>
            <Route path="/property_groups" element={<AsyncPage page="admin/property_groups/index" />} />
            <Route path="/property_groups/property/:id" element={<AsyncPage page="admin/property_groups/properties/form_page" />} />
            <Route path="/categories" element={<AsyncPage page="admin/categories/index_page" />} />
            <Route path="/categories/:id/configure" element={<AsyncPage page="admin/categories/configure_page/index" />} />
            <Route path="/categories/:id/keywords" element={<AsyncPage page="admin/categories/keywords_page/index" />} />
            <Route path="/flaws" element={<AsyncPage page="admin/flaws/index_page" />} />
            <Route path="/blackwords" element={<AsyncPage page="admin/blackwords/index_page" />} />
            <Route path="/reporting" element={<AsyncPage page="admin/reporting_page" />} />
            <Route path="/content_editors_management" element={<AsyncPage page="admin/content_editors_management" />} />
          </>
          )}
      </Route>
      <Route path="*" element={<Alert severity="error" data-testid="error=msg">{t('page_not_found')}</Alert>} />
    </Routes>
  );
}

export default AppRoutes;
