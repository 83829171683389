import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Authentication from 'components/authentication';
import ApplicationLayout from 'components/layouts/application_layout';
import CustomMuiTheme from 'components/custom_mui_theme';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import DeTranslations from 'translations/de.json';
import EnTranslations from 'translations/en.json';
import PlTranslations from 'translations/pl.json';
import EnValidations from 'translations/validator_en.json';
import DeValidations from 'translations/validator_de.json';
import PlValidations from 'translations/validator_pl.json';

import { systemSettings } from 'global_vars';
import { Provider as ReduxProvider } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { client } from './lib/api';
import AppRoutes from './components/app_routes';
import store from './reducers';

// TODO: load only current locale's translations
// @ts-ignore
SimpleReactValidator.addLocale('en', EnValidations);
// @ts-ignore
SimpleReactValidator.addLocale('de', DeValidations);
// @ts-ignore
SimpleReactValidator.addLocale('pl', PlValidations);

i18next.init({
  interpolation: { escapeValue: false },
  lng: systemSettings('currentLocale'),
  resources: { // TODO: load only current locale's translations
    en: EnTranslations,
    de: DeTranslations,
    pl: PlTranslations,
  },
});

function App() {
  return (
    <div className="App">
      <CustomMuiTheme>
        <ReduxProvider store={store}>
          <I18nextProvider i18n={i18next}>
            <ApolloProvider client={client}>
              <Authentication>
                <Router>
                  <ApplicationLayout>
                    <AppRoutes />
                  </ApplicationLayout>
                </Router>
              </Authentication>
            </ApolloProvider>
          </I18nextProvider>
        </ReduxProvider>
      </CustomMuiTheme>
    </div>
  );
}

export default App;
