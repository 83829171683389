import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { systemSettings } from 'global_vars';
import { Tooltip } from '@material-ui/core';
import LocaleSwitcher from './_locale_switcher';
import ArticleSearch from '../forms/ArticleSearch';

interface IProps {
  setOpen: (status: boolean) => void,
  open: boolean;
}

const StyledAppBar = styled(AppBar)`
  display: block !important;
  .title-label {
    flex-grow: 1;
    text-align: left;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .header-inner {
    max-width: 64em;
    margin-left: auto;
    margin-right: auto;
  }
  .search-form {
    background-color: #8940f2;
    box-shadow: none;
    input { color: #fff; }
    svg { color: #fff; }
  }
  hr { height: 20px; margin: 0 9px; }
`;

export default function Header({ setOpen, open = false }: IProps) {
  const [t] = useTranslation(['application_layout']);
  const { logout } = useAuth0();
  const onLogout = () => {
    systemSettings('', { resetAll: true });
    logout({ returnTo: window.location.origin });
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <StyledAppBar position="fixed" id="main-header">
      <Toolbar className="header-inner">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" className="title-label" color="inherit">
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            { t('title') }
          </Link>
        </Typography>
        <ArticleSearch />
        <Divider orientation="vertical" />
        <LocaleSwitcher />
        <Divider orientation="vertical" />
        <Tooltip title={t('logout') || 'Log Out'}>
          <IconButton data-testid="logout-btn" color="inherit" onClick={onLogout}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </StyledAppBar>
  );
}
