import React, { useState, useEffect, ReactNode } from 'react'; // eslint-disable-line no-unused-vars
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import LoadingBar from './loading_bar';
import { useLast } from '../lib/hook_helpers';
import { IGenericObject } from '../interfaces';

type Props = {
  children: ReactNode|ReactNode[],
  loading?: boolean,
  error?: string|undefined|ApolloError,
  delay?: number,
  loadingMsg?: string, // ignored for now
  expireLoading?: number,
  expiredLoadingMsg?: string,
};

function isSuccess(loading:boolean, error: string|undefined) {
  return !loading && !error;
}

export function parseError(errorArg: Props['error']) {
  let res = errorArg;
  if (errorArg && typeof errorArg === 'object') {
    const errorObj = errorArg.networkError as IGenericObject;
    const errors = (errorObj?.result?.errors || []);
    res = errors.map((err: IGenericObject|string) => (typeof err === 'object' ? err.message : err))
      .join(', ');
    res = res || errorArg.message;
  }
  return res as string;
}

const DelayedLoading = (props: Props):JSX.Element => {
  const [t] = useTranslation(['delay_loading_comp']);
  const {
    children, error: errorArg = '',
    delay = 500, loading = true,
    expireLoading = 1000 * 20,
    expiredLoadingMsg = t('timeout_error'),
  } = props;
  const error = parseError(errorArg);
  const success = isSuccess(loading, error);
  const [awaitLoading, setAwaitLoading] = useState(true);
  const [expiredLoading, setExpiredLoading] = useState(false);
  const [successBefore, setSuccessBefore] = useState(success);
  const loadingRef = useLast(loading);
  const errorValue = error || (expiredLoading ? expiredLoadingMsg : '');
  if (!successBefore && success) setSuccessBefore(true);
  if (!loading && awaitLoading) setAwaitLoading(false);
  if (!loading && expiredLoading) setExpiredLoading(false);

  useEffect(() => {
    let timer:number|null = null;
    if (loading) {
      setExpiredLoading(false);
      timer = setTimeout(() => { // delay for loading
        timer = setTimeout( // delay for timeout
          () => {
            if (loadingRef.current) setExpiredLoading(true);
          }, expireLoading,
        );
        setAwaitLoading(false);
      }, delay);
    }
    return () => { if (timer) clearTimeout(timer); };
  }, [loading]);

  if (loading && awaitLoading) return <></>;
  return (
    <>
      {errorValue && <Alert severity="warning">{errorValue}</Alert>}
      {loading && <LoadingBar />}
      {(successBefore || (!loading && !errorValue)) && children}
    </>
  );
};

export default DelayedLoading;
