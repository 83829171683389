/* eslint-disable import/prefer-default-export */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { envVar, systemSettings } from 'global_vars';

const httpLink = createHttpLink({
  uri: `${envVar('REACT_APP_API_URI')}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = systemSettings('currentToken');
  return {
    headers: {
      ...headers,
      locale: systemSettings('currentLocale'),
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
