import React from 'react';
import { simpleQuery } from 'graphql/helpers';
import { useQuery } from '@apollo/client';
import Login from 'pages/login';
import { Auth0Provider } from '@auth0/auth0-react';
import DelayedLoading from 'components/delay_loading';
import { envVar, systemSettings } from 'global_vars';

function query() {
  return simpleQuery('userCurrent', { fields: 'id name email roles' });
}

function Authentication({ children } : { children: JSX.Element }) {
  const { data: userData, loading, error } = useQuery(query());
  const authenticated = userData && !loading;
  const unauthorized = (error?.networkError as any)?.statusCode === 401;
  if (userData) systemSettings('currentUser', { value: userData.userCurrent });
  return (
    <Auth0Provider
      domain={envVar('REACT_APP_AUTH0_DOMAIN') || ''}
      clientId={envVar('REACT_APP_AUTH0_CLIENT') || ''}
      redirectUri={window.location.origin}
    >
      <DelayedLoading loading={loading} error={unauthorized ? '' : error?.message}>
        { authenticated ? children : <Login /> }
      </DelayedLoading>
    </Auth0Provider>
  );
}
export default Authentication;
