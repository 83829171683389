import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function LoadingBar() {
  return (
    <LinearProgress
      style={{
        position: 'fixed', left: 0, top: 0, width: '100%', zIndex: 9999,
      }}
      color="secondary"
    />
  );
}
