import { createSlice } from '@reduxjs/toolkit';
import { IArticle, IDPartial } from 'interfaces';
import { DeepPick } from 'lib/deep_pick';
import { IFormProps, TArticle } from 'pages/articles/form_page/_utils';

type TPropertyValue = IDPartial<TArticle['propertyValues'][0]>;
export type IState = IDPartial<DeepPick<IArticle, {
  id: true,
  sizeLabelRemoved: true,
  fabricLabelRemoved: true,
  containsNonTextiles: true,
  articleConditionId: true,
  photographyComment: true,
  flawIds: true,
}> & {
  title: TArticle['title'] & { colorDefault?: string, colorTmp?: string },
  searchTitle: TArticle['searchTitle'] & { colorDefault?: string, colorTmp?: string },
  propValues?: { [id: string]: TPropertyValue },
  data: IFormProps // full query data
}>;

const initialState: IState = {
  propValues: {},
};
const slice = createSlice({
  name: 'articleForm',
  initialState,
  reducers: {
    setProperty: (state, { payload }: { payload: TPropertyValue }) => {
      const propValues = { ...state.propValues, ...{ [payload.propertyId || -1]: payload } };
      return { ...state, ...{ propValues } };
    },
    setValues: (state, { payload }: { payload: IState }) => ({ ...state, ...payload }),
    resetState: () => initialState,
  },
});
export const { actions } = slice;
export default slice.reducer;
