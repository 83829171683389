import React from 'react';
import styled from 'styled-components';
import { IGenericObject } from 'interfaces'; // eslint-disable-line no-unused-vars
import Header from './header';
import Navigation from './navigation';

const Style = styled.div`
  & {
    transition: margin 350ms;
  }
  &.navigation-open{
    margin-left: 15.6em;
  }
  > main {
    max-width: 64em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5em;
  }
  table > thead {
    th { background-color: #17d0af; font-weight: bold; color: #fff; }
  }
`;

export default function ApplicationLayout({ children }: IGenericObject) {
  const [openNavigation, setOpenNavigation] = React.useState(false);

  return (
    <Style className={`${openNavigation ? 'navigation-open' : ''}`}>
      <Header setOpen={setOpenNavigation} open={openNavigation} />
      <Navigation setOpen={setOpenNavigation} open={openNavigation} />
      <main id="main">{children}</main>
    </Style>
  );
}
