import {
  IconButton, InputBase, Box,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

const ArticleSearch = () => {
  const [t] = useTranslation(['application_layout']);
  const navigate = useNavigate();
  const searchRef = React.useRef<HTMLInputElement>();
  const searchArticle = (ev: React.FormEvent<HTMLDivElement>) => {
    const val = searchRef.current?.value;
    if (val) navigate(`/articles/search/${val}`, { replace: false });
    if (searchRef.current) searchRef.current.value = '';
    ev.preventDefault();
  };

  const location = useLocation();
  useEffect(() => {
    const personalDashboard = location.pathname === '/';
    if (personalDashboard) {
      const input = searchRef.current as HTMLInputElement;
      if (input) input.focus();
    }
  }, [location.pathname]);

  return (
    <Box
      component="form"
      className="search-form"
      onSubmit={searchArticle}
      boxShadow={3}
      display="flex"
      borderRadius={4}
      py={0.5}
      alignItems="center"
    >
      <Box mx={1}>
        <IconButton type="submit" size="small">
          <SearchIcon />
        </IconButton>
      </Box>
      <InputBase placeholder={t('find_article')} name="search" inputRef={searchRef} />
    </Box>
  );
};

export default ArticleSearch;
