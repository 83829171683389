// TODO: move to /lib/graphql.ts and remove non used methods
import { gql, ApolloClient, StoreObject } from '@apollo/client'; // eslint-disable-line no-unused-vars
import { IGenericObject } from 'interfaces/index'; // eslint-disable-line no-unused-vars
import { DocumentNode } from 'graphql'; // eslint-disable-line no-unused-vars

interface IQueryInfo {
  params?:IGenericObject,
  variables?:IGenericObject,
  fields?:string|IGenericObject,
  queryInfo?:string
  alias?:string
  extraQuery?:string
}

function capitalizeFirstLetter(text:string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

// Sample: const sample = ['id', 'articleNo', { category: ['id', 'name'] }];
export function objToQuery(data: (string|IGenericObject)[]|IGenericObject): string {
  const parseObject = (obj: IGenericObject): string => (
    Object.keys(obj).map((key: string) => (
      `${key} ${typeof obj[key] === 'object' ? ` { ${objToQuery(obj[key])} } ` : ''}`)).join(' ')
  );

  const parseArray = (values: (string|IGenericObject)[]): string => (
    values.map((prop: string|IGenericObject) => (
      typeof prop === 'string' ? prop : parseObject(prop)
    )).join(' ')
  );

  return Array.isArray(data) ? parseArray(data) : parseObject(data);
}

function asQueryParams(params:IGenericObject, forceString = true): string[] {
  const parsedParams = Object.keys(params || {}).map((key) => {
    let value = params[key];
    if (typeof value === 'string' && forceString) value = `"${params[key]}"`;
    return `${key.replace('*', '')}: ${value}`;
  });
  return parsedParams;
}

const asQueryVars = (variables: IGenericObject): IGenericObject => (
  Object.keys(variables)
    .filter((key) => !key.endsWith('*'))
    .reduce((data, key: string) => ({ ...data, ...{ [key.replace('$', '')]: key } }), {}));

function buildQuery(queryName:string, data:IQueryInfo, body: string = '') {
  const queryAlias = data.alias || queryName;
  const varsMode = !!data.variables;
  const queryVars = varsMode ? `(${asQueryParams(data.variables || {}, false).join(', ')})` : '';
  const queryParams = asQueryParams(data.params || {});
  const varsParams = asQueryParams(asQueryVars(data.variables || {}), false);
  const params = queryParams.concat(varsParams).join(', ');

  const q = `query ${queryAlias}${queryVars}{
    ${queryName} ${params ? `(${params})` : ''} {
      ${body}
    }
    ${data.extraQuery || ''}
  }`;
  return gql(q);
}

// Builds a simple graphql query ready to be used with useQuery
// sample1: Simple query with defined params (static params)
//          simpleQuery('categories', { params: { id: 10 }, fields: 'id name' })
// sample2: Using alias and defining variables (dynamic params defined with useQuery)
//          simpleQuery('categories',
//            { variables: { $id: Integer }, fields: 'id name', alias: 'customCategories',
//              extraQuery: 'articleConditions { id name }' })
//          You can omit variable value assignation by adding "*" to the end and assign it manually
//          for inner queries, sample:
//          ('..', { variables: { $search*: String }, fields: 'id items(search: $search) { id }' })
export function simpleQuery(queryName:string, data:IQueryInfo) {
  const body = typeof data.fields === 'object' ? objToQuery(data.fields) : data.fields || 'id';
  return buildQuery(queryName, data, body);
}

// Builds a relay graphql query ready to be used with useQuery
// sample1: Simple query with defined params
//          relayQuery('categories',
//            { params: { id: 10 }, fields: 'id name', queryInfo: 'hasNextPage' })
// sample2: Using alias and defining variables
//          relayQuery('categories',
//            { variables: { $id: Integer }, fields: 'id name', alias: 'customCategories' })
export function relayQuery(queryName:string, data:IQueryInfo) {
  const variables = { ...(data.variables || {}), ...{ $after: 'String' } };
  const body = `
    nodes { ${typeof data.fields === 'object' ? objToQuery(data.fields) : data.fields || 'id'} }
    pageInfo { ${data.queryInfo || 'hasNextPage endCursor'} }
  `;
  return buildQuery(queryName, { ...data, ...{ variables } }, body);
}

// Builds a mutation graphql query ready to be used with useMutation
// Sample 1: mutationQuery('categoryCreate', 'category { id name }')
export function mutationQuery(operation:string, fields?:string|IGenericObject) {
  const q = `mutation ${operation}($input: ${capitalizeFirstLetter(operation)}Input!) {
          ${operation}(input: $input) {
              ${typeof fields === 'object' ? objToQuery(fields) : fields || ''}
              errors
          }
      }`;
  return gql(q);
}

interface IUpdateCacheParams {
  client:ApolloClient<any>,
  parentObj:IGenericObject,
  obj:IGenericObject,
  field:string,
  action?:string
}
export function updateCacheFor({
  client, parentObj, obj, field, action = 'create',
} : IUpdateCacheParams) {
  client.cache.modify({
    id: client.cache.identify(parentObj as StoreObject),
    fields: {
      [field]: (currentRefs, { readField }) => {
        switch (action) {
          case 'delete': // update cache when deleted an item
            return currentRefs.filter((ref:any) => readField('id', ref) !== obj.id);
          case 'update': // update cache when updated an item
            return currentRefs.map((ref:any) => (readField('id', ref) === obj.id ? obj : ref));
          default: // update cache when added a new item
            return [...currentRefs, obj];
        }
      },
    },
  });
}

export function updateQueryCache(client: ApolloClient<any>, queryObj: DocumentNode,
  key: string, nodes: IGenericObject[]) {
  const data = client.readQuery({ query: queryObj });
  const keyInfo = { ...data[key], ...{ nodes } };
  const newData = { ...data, ...{ [key]: keyInfo } };
  client.writeQuery({ query: queryObj, data: newData });
}
