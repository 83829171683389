import { systemSettings } from 'global_vars';

export function hasRole(role: string): boolean {
  const userRoles: string[] = systemSettings('currentUser')?.roles || [];
  return userRoles.includes(role);
}

export const isManager = () => hasRole('admin') || hasRole('content_mgr');
export const isContentReviewer = () => isManager() || hasRole('content_reviewer');
export const isContentEditor = () => isManager() || hasRole('content_editor');
