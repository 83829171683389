import React, { ReactElement } from 'react';
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const FONTS = "'Lato', sans-serif";

const theme = createMuiTheme({
  typography: {
    fontFamily: FONTS,
    h1: {
      fontWeight: 'bold',
      fontSize: '3.25rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2.75rem',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
    button: {},
  },
  palette: {
    primary: {
      main: deepPurple.A700,
    },
    secondary: {
      main: '#16D0AF',
    },
  },
});

interface IProps {
  children: ReactElement;
}
export default function CustomMuiTheme({ children }: IProps) {
  return (
    <ThemeProvider theme={theme}>{ children }</ThemeProvider>
  );
}
