import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer/Drawer';
import styled from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';
import { isContentReviewer, isManager } from 'lib/user_helpers';
import { TFunction } from 'i18next';

interface IProps {
  setOpen: (status: boolean) => void,
  open: boolean
}

const StyledCollapse = styled(Collapse)`
  margin-left: 1.875em;
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  color: inherit;
  text-decoration: none;
`;
const StyledDrawer = styled(Drawer)`
  margin-top: 3em;
  > div {
    width: 15.6em;
  }
  .text-right {
    text-align: right;
  }
  .text-bold span {
    font-weight: bold;
  }
`;

const ManagerMenuItems = ({ t }:{t: TFunction}) => (
  <>
    <ListItem>
      <StyledLink to="/admin/property_groups">
        <ListItemText primary={t('property_groups')} />
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to="/admin/categories">
        <ListItemText primary={t('categories')} />
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to="/admin/flaws">
        <ListItemText primary={t('flaws')} />
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to="/admin/blackwords">
        <ListItemText primary={t('blackwords')} />
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to="/admin/reporting">
        <ListItemText primary={t('reporting')} />
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to="/admin/content_editors_management">
        <ListItemText primary={t('content_editors_management')} />
      </StyledLink>
    </ListItem>
  </>
);

export default function Navigation({ setOpen, open = false }: IProps) {
  const [t] = useTranslation(['sidebar']);
  const handleDrawerClose = (ev: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    ev.stopPropagation();
  };
  const hideOnClickLink = (ev: React.MouseEvent<HTMLElement>) => {
    const target = ev.target as HTMLElement;
    if (['A', 'SPAN'].includes(target.tagName)) setOpen(false);
  };

  return (
    <StyledDrawer variant="persistent" anchor="left" open={open} onClick={hideOnClickLink}>
      <div className="text-right">
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem>
          <StyledLink to="/">
            <ListItemText primary={t('home')} />
          </StyledLink>
        </ListItem>
      </List>
      { isContentReviewer() && (
        <>
          <Divider />
          <List data-testid="management-links">
            <ListItem>
              <ListItemText className="text-bold" primary={t('admin')} />
            </ListItem>
            <StyledCollapse in timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                { isContentReviewer()
                  && (
                  <ListItem>
                    <StyledLink to="/admin/dashboard">
                      <ListItemText primary={t('dashboard')} />
                    </StyledLink>
                  </ListItem>
                  )}

                { isManager() && <ManagerMenuItems t={t} />}
              </List>
            </StyledCollapse>
          </List>
        </>
      )}
    </StyledDrawer>
  );
}
