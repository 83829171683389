import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DelayedLoading from 'components/delay_loading';
import { systemSettings } from 'global_vars'; // eslint-disable-line no-unused-vars
import { IGenericObject } from 'interfaces';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button'; // eslint-disable-line no-unused-vars
import { Box, Typography } from '@material-ui/core';

const redirectKey = 'loginRedirect';
function onLogin({ __raw: token }: IGenericObject) {
  systemSettings('currentToken', { value: token });
  const previousUrl = localStorage.getItem(redirectKey);
  localStorage.removeItem(redirectKey);
  window.location.href = previousUrl || '/';
}

function savePreviousUrl() {
  if (localStorage.getItem(redirectKey)) return;
  localStorage.setItem(redirectKey, window.location.href);
}

export default function Login() {
  const [t] = useTranslation(['login_page']);
  const {
    isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading,
  } = useAuth0();

  if (isAuthenticated) {
    getIdTokenClaims().then((d) => {
      savePreviousUrl();
      onLogin(d);
    });
  }
  const initLogin = () => {
    savePreviousUrl();
    loginWithRedirect();
  };

  return (
    <DelayedLoading loading={isLoading}>
      { !isAuthenticated && (
        <Box
          style={{ margin: '0 auto', maxWidth: 500, textAlign: 'center' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="90vh"
          paddingBottom="10vh"
        >
          <Box marginBottom={3}>
            <img src={`${process.env.PUBLIC_URL}/buddyandselly_logo.jpg`} style={{ maxWidth: '50%', marginBottom: '-2rem' }} alt="Buddy & Selly" />
            <Typography color="secondary" variant="h1">{ t('title') }</Typography>
          </Box>
          <Button
            data-testid="loginBtn"
            onClick={initLogin}
            variant="contained"
            color="primary"
          >
            {t('login_btn')}
          </Button>
        </Box>
      )}
    </DelayedLoading>
  );
}
