import { IGenericObject } from 'interfaces';

function envVar(key: string) {
  const windowEnv = ((window as IGenericObject).ENVS || {})[key]; // base64 encoded value
  const decodedWindowVar = atob(windowEnv || '').replace(/\n$/, '');
  return process.env[key] || decodedWindowVar;
}

// Static settings
const globalVarsType = {
  defaultLanguage: 'de',
  availableLanguages: ['de', 'en', 'pl'],
  buddyUrl: envVar('REACT_APP_BUDDY_URL') || 'https://buddy.buddyandselly.com/',
  properties: {
    withOptions: ['choice', 'multiple_dropdown', 'precise_material'],
  },
  descriptionStates: ['initial', 'in_progress', 'waiting_approval', 'done', 'draft'],
  // Here define all global settings
} as const;
const globalVars = globalVarsType as IGenericObject;

// Freeze so these can only be defined in this file.
Object.freeze(globalVars);

interface ISystemSettingsProps {
  value?: any // any value to be saved for key
  remove?: boolean // remove current value for key
  resetAll?: boolean // reset all settings to default state (logout)
}
// TODO: look for alternatives to store persistent settings
// Save and retrieve customizable persistent settings
// Sample to save a key: systemSettings('token', { value: 'my_token' })
// Sample to retrieve a key: systemSettings('token')
function systemSettings(key: string, props: ISystemSettingsProps = {}) {
  const defaultSettings = { currentLocale: globalVars.defaultLanguage };
  const settingsStr = localStorage.getItem('appSettings') || JSON.stringify(defaultSettings);
  let settings = JSON.parse(settingsStr) as IGenericObject;
  if (!props) return settings[key];

  if (props.remove) delete settings[key];
  if ('value' in props) settings[key] = props.value;
  if (props.resetAll) settings = defaultSettings;
  localStorage.setItem('appSettings', JSON.stringify(settings));
  return settings[key];
}

function currentLocale() {
  return systemSettings('currentLocale') as typeof globalVarsType.availableLanguages[number];
}

// eslint-disable-next-line import/prefer-default-export
export {
  globalVars, globalVarsType, currentLocale, systemSettings, envVar,
};
