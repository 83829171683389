import { IGenericObject } from 'interfaces'; // eslint-disable-line no-unused-vars
import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

// To compare with previous data
interface RefObject<T> { current: T | null | undefined; }
export function usePrevious(value:any) {
  const ref = React.useRef() as RefObject<IGenericObject>;
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Alternative to useEffect, bk it is called after rendering component and not before
//   @callback is executed the first time the component was called
export function useFirstTime(callback: () => void) {
  const previousData = usePrevious({});
  if (!previousData) callback();
}

// Similar to use effect that always keeps the first value
export function useCache(fn: () => any) {
  const [cachedVal] = React.useState(() => fn());
  return cachedVal;
}

// return a reference where lastRef.current is always the last value
export function useLast(value:any) {
  const ref = React.useRef() as RefObject<any>;
  ref.current = value;
  return ref;
}

// Callback is executed right after current reference is assigned
// BUG: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
export function useCurrentReference(ref: React.MutableRefObject<any>|null,
  callback: (p:any)=>void) {
  useEffect(() => {
    if (ref) {
      const interval = setInterval(() => {
        if (ref.current) {
          callback(ref.current);
          clearInterval(interval);
        }
        return () => clearInterval(interval);
      }, 1);
    }
  }, []);
}

// After reference element is rendered, waits for inner html to be rendered.
// Once element (filter) is rendered, callback is called
// If filter is empty, it waits for ref Element
type refType = React.MutableRefObject<any>|null;
export function useWaitForElement(ref: refType, filter: string|null,
  callback: (ele?: HTMLElement) => void) {
  const maxRetry = 20;
  let counter = 0;
  let timeoutId: null|number = null;
  const findElement = () => {
    const res = filter ? ref?.current?.querySelector(filter) as HTMLElement : ref?.current;
    if (res) { callback(res); return true; }
    if (!res && counter === maxRetry) {
      console.error('Element not found:', filter);
      return true;
    }
    return false;
  };

  const findAgain = () => setTimeout(() => {
    if (!findElement()) {
      counter += 1;
      timeoutId = findAgain();
    }
  }, counter * 100);

  useEffect(() => {
    if (!findElement()) timeoutId = findAgain();
    return () => { if (timeoutId) clearTimeout(timeoutId); };
  }, []);
}

// Hook that compares previous value with the current value
// @param onDif(optional) is called if not equal
// @return true if equal, otherwise false
export function useCompareLast(data: IGenericObject, onDif?: (data: IGenericObject) => void) {
  const ref = React.useRef() as RefObject<any>;
  const { current } = ref;
  ref.current = data;
  const equal = isEqual(current, data);
  if (!equal && onDif) onDif(data);
  return equal;
}

// calls callback with changed values (only if there is at least one changed value)
export function useDifferent(data: IGenericObject, callback: (changed: IGenericObject) => void) {
  const ref = React.useRef() as RefObject<any>;
  const { current } = ref;
  ref.current = data;
  const currentData = current || {};
  const diff = Object.keys(data).reduce((res, key) => {
    if (!isEqual(data[key], currentData[key])) res[key] = data[key];
    return res;
  }, {} as IGenericObject);

  if (Object.keys(diff).length) callback(diff);
}
