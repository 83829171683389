import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { systemSettings } from 'global_vars';
import { Tooltip } from '@material-ui/core';

export default function LocaleSwitcher() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [t] = useTranslation(['application_layout']);
  const currentLocale = systemSettings('currentLocale');
  const handleClick = (event: React.ChangeEvent<any>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const setLocale = (locale: string) => {
    closeMenu();
    if (currentLocale === locale) return;

    systemSettings('currentLocale', { value: locale });
    window.location.reload();
  };

  function menuProps(locale: string) {
    return {
      selected: currentLocale === locale,
      onClick: () => setLocale(locale),
      'data-testid': locale,
    };
  }

  return (
    <>
      <Tooltip title={t('switch_locale') || 'Switch language'}>
        <Button data-testid="switch-locale-btn" onClick={handleClick} style={{ color: 'white' }}>
          { currentLocale }
          <ExpandMoreIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem {...menuProps('de')}>{t('locales.de')}</MenuItem>
        <MenuItem {...menuProps('en')}>{t('locales.en')}</MenuItem>
        <MenuItem {...menuProps('pl')}>{t('locales.pl')}</MenuItem>
      </Menu>
    </>
  );
}
